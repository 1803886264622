import { FelaCSS, FelaCSSWithCustomSelectors } from '../../../../components/fela/flowtypes';
import { alpha } from '../../../../fela-utils/color-mixer';
import { colors } from '../../../../themes/variables';
import { SharedTypes } from './input';

interface PropTypes extends SharedTypes {
  showReset?: boolean;
  hasError?: boolean;
  icon?: boolean;
}

type StyleTypes = {
  wrapper: FelaCSS;
  inputWrapper: FelaCSS;
  input: FelaCSSWithCustomSelectors;
  iconWrap: FelaCSS;
  backIconWrap: FelaCSS;
  resetWrap: FelaCSS;
  endIconWrap: FelaCSS;
};

const styles = ({
  disabled,
  icon,
  backIcon,
  readOnly,
  readOnlyActive,
  iconWidth = 10,
  showReset,
  hasError,
}: PropTypes): StyleTypes => {
  const shadowColor = alpha(colors.indigoCrush80, 20);
  const iconSpace = `${19 + iconWidth}px`;

  const shadowStyle: FelaCSS = {
    boxShadow: disabled ? undefined : `2px 2px 4px 0 ${shadowColor}`,
  };

  const iconWrapGeneric: FelaCSS = {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    transform: 'translate(0, -50%)',
    justifyContent: 'center',
    pointerEvents: 'none',
  };

  return {
    wrapper: {
      position: 'relative',
    },

    inputWrapper: {
      position: 'relative',
      transform: 'translate3d(0, 0, 0)', // Fix for a moving icon inside of a modal
    },

    input: {
      flexShrink: 0,
      paddingLeft: icon ? iconSpace : '10px',
      paddingRight:
        showReset && backIcon ? '85px' : backIcon ? '41px' : showReset ? '44px' : '13px',
      height: '44px',
      width: '100%',
      fontDefault: 16,
      color: disabled ? colors.space30 : colors.space,
      lineHeight: '20px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: hasError ? colors.blushTangerine120 : colors.space40,
      borderRadius: '4px',
      boxSizing: 'border-box',
      opacity: 1,
      backgroundColor: disabled ? colors.space04 : colors.white,
      cursor: readOnly ? 'default' : 'initial',
      WebkitAppearance: 'none',
      pointerEvents: readOnly ? 'none' : undefined,

      ':disabled': {
        WebkitTextFillColor: colors.space30, // safari text-color fix
        borderColor: colors.space40,
      },

      ':read-only': {
        userSelect: 'none',
        color: readOnlyActive ? colors.space : colors.space30,
      },

      '::placeholder': {
        color: colors.space30,
      },

      '::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },

      '::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
      },

      ':hover': shadowStyle,

      ':active': {
        outline: '0px',
        WebkitAppearance: 'none',
      },

      ':focus': {
        ...shadowStyle,
        borderColor: hasError ? colors.blushTangerine120 : colors.indigoCrush80,
        outline: '0px',
        WebkitAppearance: 'none',
      },
    },

    iconWrap: {
      ...iconWrapGeneric,
      alignItems: 'flex-start',
      paddingLeft: '12px',
      left: '0',
      height: '41px',
      width: iconSpace,
    },

    backIconWrap: {
      ...iconWrapGeneric,
      alignItems: 'center',
      right: '0',
      height: '44px',
      width: '41px',
      backgroundColor: disabled
        ? colors.space40
        : hasError
        ? colors.blushTangerine120
        : colors.indigoCrush,
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },

    resetWrap: {
      ...iconWrapGeneric,
      zIndex: 1,
      right: backIcon ? '42px' : '0',
      top: '50%',
      height: '42px',
      width: '42px',
    },
    endIconWrap: {
      ...iconWrapGeneric,
      right: 0,
    },
  };
};

export default styles;
