import React, { ChangeEventHandler, Component } from 'react';
import { TSetRef } from '../../types';
import Box, { IProps as IBoxProps } from './Box';
import Text from './Text';

// Universal text input component. By default, it looks like editable text.
// For underline or the other effects, make a new component. Check TextInputBig.
// TODO: Multiline and rows. Use content editable rather because of links?

export interface IProps extends IBoxProps {
  disabled?: boolean;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  setRef?: TSetRef;
}

// Like Twitter.
const defaultMaxLength = 140;

class TextInput extends Component<IProps> {
  render() {
    const { maxLength = defaultMaxLength, onChange, style, setRef, ...restProps } = this.props;

    return (
      <Box>
        <Text
          as="input"
          maxLength={maxLength}
          setRef={setRef}
          onChange={onChange || null}
          {...(restProps.disabled ? { opacity: 0.5 } : null)}
          {...restProps}
          style={style}
        />
      </Box>
    );
  }
}

export default TextInput;
