import { colors } from '@bridebook/ui';
import { FelaCSS } from '../../../../components/fela/flowtypes';
import InputStyles from '../input/input.style';
import { SharedTypes } from './text-field';

interface PropTypes extends SharedTypes {
  hasError: boolean;
}

interface StyleTypes {
  textarea: FelaCSS;
  label: FelaCSS;
}

const styles = ({ disabled, hasError, isPlaceholder }: PropTypes): StyleTypes => {
  const SharedStyle = InputStyles({ disabled, hasError });

  return {
    textarea: {
      ...SharedStyle.input,
      borderRadius: '5px',
      paddingLeft: '13px',
      paddingRight: '13px',
      paddingTop: '12px',
      paddingBottom: '12px',
      height: 'auto',
      resize: 'none',
      ...(isPlaceholder && { color: colors.space30 }),
    },
    label: {
      fontDefaultSemiBold: 14,
      lineHeight: '20px',
      color: disabled ? colors.space40 : colors.space,
    },
  };
};

export default styles;
