import React, { FunctionComponent } from 'react';
import IconCalendar from '../../../../components/svg/icon-calendar';
import Input, { IProps } from '../input/input';

const InputDatepicker: FunctionComponent<IProps> = (props) => (
  <Input
    placeholder="Select something on the list"
    {...props}
    backIcon={IconCalendar}
    backIconWidth={17}
  />
);

export default InputDatepicker;
