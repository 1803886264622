import { getI18n } from 'lib/i18n/get-i18n';

/*
 * Verify recaptcha v3 request
 * https://developers.google.com/recaptcha/docs/verify
 */
const verifyRecaptcha = async (token: string) => {
  const response = await fetch(`/api/recaptcha-verify`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  });

  if (!response.ok || response.status !== 200) {
    throw Error(getI18n().t('couldNotVerifyRecaptcha'));
  }

  const responsePayload = await response.json();

  if (responsePayload.score && responsePayload.score > 0.3) {
    return true;
  }

  return false;
};

export default verifyRecaptcha;
