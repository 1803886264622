import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';
import { SharedTypes as PropTypes } from './error';

interface StyleTypes {
  errorMessageWrap: FelaCSS;
  errorMessageText: FelaCSS;
}

const styles = ({ errorTextHide }: PropTypes): StyleTypes => ({
  errorMessageWrap: {
    flexDirection: 'row',
    marginTop: '9px',
    visibility: errorTextHide ? 'hidden' : 'visible',
    pointerEvents: errorTextHide ? 'none' : 'all',
  },

  errorMessageText: {
    marginLeft: '8px',
    fontDefault: 14,
    lineHeight: '15px',
    color: colors.blushTangerine120,
  },
});

export default styles;
