import { atom, selector } from 'recoil';
import type { IDatePicker, IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';

export interface IEnquiryFormFields {
  partnerName1: string;
  partnerName2?: string;
  email: string;
  weddingDate?: IDatePicker;
  weddingDateDatePicker: IDatePickerUI | null;
  message: string;
  guestsInitialTarget: number;
  phone: string;
}

export interface IWeddingFieldsToSend {
  contacts: {
    email: string;
    phone?: string;
  };
  partners: [] | string[];
  date?: IDatePicker;
  guests?: {
    estimate: number;
  };
}

export const initialState = {
  partnerName1: '',
  partnerName2: '',
  email: '',
  weddingDate: undefined,
  weddingDateDatePicker: null,
  message: '',
  guestsInitialTarget: 0,
  phone: '',
};

export const enquiryFormFieldsState = atom<IEnquiryFormFields>({
  key: 'enquiryFormFields',
  default: initialState,
});

/*
 * Map object to appropriate format that ISupplierWedding accepts
 */
export const weddingFieldsToSendSelector = selector({
  key: 'weddingFieldsToSend',
  get: ({ get }): IWeddingFieldsToSend => {
    const enquiryFields = get(enquiryFormFieldsState);
    const { partnerName1, partnerName2, email, weddingDate, guestsInitialTarget, phone } =
      enquiryFields;

    const weddingFieldsToSend: IWeddingFieldsToSend = {
      contacts: {
        email,
      },
      partners: [partnerName1, partnerName2 || ''],
    };

    if (phone) {
      weddingFieldsToSend.contacts.phone = phone;
    }

    if (weddingDate) {
      weddingFieldsToSend.date = weddingDate;
    }

    if (guestsInitialTarget) {
      weddingFieldsToSend.guests = { estimate: guestsInitialTarget };
    }

    return weddingFieldsToSend;
  },
});
