import { getI18n } from 'lib/i18n/get-i18n';

export const getDefaultFields = () => {
  const i18n = getI18n();

  return {
    email: {
      type: 'email',
      label: i18n.t('email.label'),
      placeholder: i18n.t('enter.your.email'),
      pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$',
      name: 'email',
    },
    partnerName1: {
      label: i18n.t('yourName'),
      type: 'text',
      name: 'partnerName1',
      placeholder: i18n.t('yourName'),
    },
  };
};

export const getOptionalFields = () => {
  const i18n = getI18n();

  return {
    phone: {
      type: 'tel',
      placeholder: i18n.t('enterYourPhoneNumber'),
      pattern: '[\\s()+-]*([0-9][\\s()+-]*){6,20}(?:[.\\- ])?',
      name: 'phone',
      label: 'Phone',
    },
    partnerName2: {
      label: i18n.t('yourPartnerName'),
      type: 'text',
      name: 'partnerName2',
      placeholder: i18n.t('yourPartnerName'),
    },
    guestsInitialTarget: {
      label: i18n.t('estimatedGuests'),
      type: 'number',
      name: 'guestsInitialTarget',
      placeholder: i18n.t('howManyGuests'),
      pattern: '[0-9]*',
      min: '0',
      inputMode: 'numeric',
    },
    weddingDate: {
      label: i18n.t('visitDate'),
      type: 'date',
      name: 'weddingDate',
      placeholder: i18n.t('pickADate'),
    },
    message: {
      label: i18n.t('editYourMessageBelow'),
      type: 'message',
      name: 'message',
      placeholder: i18n.t('sendUsYourAvailability'),
    },
  };
};
