import { defaultLocale } from 'i18n/config';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import React from 'react';
import IframePage from 'components/iframe-page';
import nextI18NextConfig from '../next-i18next.config';

export default function Index() {
  const { t } = useTranslation('common');
  return (
    <>
      <Head>
        <title>{t('widgetTitle')}</title>
        <meta name="description" content={t('widgetDescription')} />
        <meta name="msapplication-config" content="/static/favicons/browserconfig.xml" />
        <meta
          content="viewport-fit=cover, width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          name="viewport"
        />
      </Head>
      <IframePage />
    </>
  );
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { locale = defaultLocale } = ctx;
  const translations = await serverSideTranslations(locale, ['common', 'ui'], nextI18NextConfig);

  return {
    props: {
      ...translations,
    },
  };
};
