import React, { FunctionComponent } from 'react';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import ComponentStyles from './label.style';

export interface SharedTypes {
  color?: string;
  disabled?: boolean;
  error?: boolean;
}

interface PropTypes extends SharedTypes, IStylingProps {
  id?: string;
  label?: string | Object;
  required?: boolean;
}

const Label: FunctionComponent<PropTypes> = ({
  id,
  label,
  required,
  error,
  disabled,
  color,
  mb = 2,
  ...restProps
}) => {
  const styles = ComponentStyles({ disabled, error, color });

  const content = label ? (
    <Box htmlFor={id} as="label" style={styles.labelWrapper} {...restProps} mb={mb}>
      <Box>{label}</Box>
      {required && <Box style={styles.labelRequired}>*</Box>}
    </Box>
  ) : null;

  return content;
};

export default Label;
