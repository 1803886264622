import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconAttentionTriangle = ({ id, color, width = 24, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M23.693 20.387c.36.668.408 1.482.12 2.03-.207.394-.56.6-1.017.6H1.311c-.529 0-.938-.224-1.153-.632-.247-.47-.203-1.107.12-1.704L10.847.859C11.14.314 11.582 0 12.054 0c.47 0 .912.314 1.207.86l10.432 19.527zm-11.703.325c.916 0 1.645-.77 1.645-1.743 0-.997-.729-1.766-1.645-1.766-.917 0-1.624.77-1.624 1.766 0 .973.707 1.743 1.624 1.743zm1.582-14.057h-3.144l.625 9.416h1.895l.624-9.416z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconAttentionTriangle;
