import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import type { TrackEventBodyWeb } from '@bridebook/toolbox/src/types';

interface IServerSideTrack {
  event: string;
  identifyProps?: Record<string, any>;
  specificEventProps?: Record<string, any>;
  category?: string;
  contactedSupplier: ISupplier;
}

const enquirySupplierTrack = ({
  event,
  identifyProps,
  specificEventProps,
  contactedSupplier,
  category = 'Enquiry widget',
}: IServerSideTrack): Promise<any> => {
  const body: TrackEventBodyWeb = {
    event,
    identifyProps,
    genericProps: {
      userId: (analytics && analytics.user && analytics.user().anonymousId()) || '',
      anonymousId: (analytics && analytics.user && analytics.user().anonymousId()) || '',
      isAnonymous: false,
      sessionId: (analytics && analytics.user && analytics.user().anonymousId()) || '',
      previousPath: '/',
      pageTitle: 'Bridebook - Enquiry widget',
      pathname: '/',
    },
    specificEventProps,
    source: 'enquiryWidget',
    contactedSupplier,
    category,
  };

  return fetch(`/api/a/t/enquiry`, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response;
  });
};

export default enquirySupplierTrack;
