import { alpha } from '../../../fela-utils/color-mixer';
import { colors } from '../../../themes/variables';
import { FelaCSS, FelaCSSWithCustomSelectors } from '../../fela/flowtypes';

interface IStyleProps {
  shown: boolean;
  label?: string;
}

interface IStyles {
  mainWrapper: FelaCSS;
  inputWrapper: FelaCSSWithCustomSelectors;
  dayPickerWrap: FelaCSSWithCustomSelectors;
  offHide: FelaCSS;
}

const styles = ({ shown, label }: IStyleProps): IStyles => {
  const shadowColor = alpha(colors.indigoCrush80, 20);

  const shadowStyle: FelaCSS = {
    boxShadow: `2px 2px 4px 0 ${shadowColor}`,
  };

  const hideFocusBorder: FelaCSS = {
    outline: '0px',
    WebkitAppearance: 'none',
  };

  const inputDefault: FelaCSS = {
    backgroundColor: colors.white,
  };

  return {
    mainWrapper: {
      position: 'relative',
      zIndex: shown ? 2002 : 0,
    },

    inputWrapper: {
      position: 'relative',
      borderRadius: '4px',
      zIndex: shown ? 2002 : 'unset',

      '> div': {
        '> div': {
          '> div': {
            '> input': shown ? { ...inputDefault, ...shadowStyle } : { ...inputDefault },
          },
        },
      },
    },

    dayPickerWrap: {
      display: shown ? 'flex' : 'none',
      alignItems: 'center',
      position: 'absolute',
      zIndex: shown ? 2001 : 'unset',
      paddingTop: '4px',
      paddingBottom: '12px',
      boxSizing: 'border-box',
      right: '0',
      top: label ? '67px' : '39px',
      width: '100%',
      maxWidth: '330px',
      backgroundColor: colors.white,
      borderColor: colors.space40,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      ...shadowStyle,

      '> .DayPicker': {
        width: '100%',
        paddingLeft: '31px',
        paddingRight: '31px',
        boxSizing: 'border-box',

        '> .DayPicker-wrapper': {
          position: 'relative',
          paddingTop: '16px',

          ':active': {
            ...hideFocusBorder,
          },

          ':focus': {
            ...hideFocusBorder,
          },

          '> .DayPicker-NavBar': {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            top: '16px',
            left: '0',
            width: '100%',
            height: '20px',

            '> .DayPicker-NavButton': {
              display: 'flex',
              width: '20px',
              height: '20px',
              background: 'url("//images.bridebook.com/assets/icons/fat-chevron-2.png") no-repeat',
              backgroundSize: '10px 6px',
              cursor: 'pointer',

              ':active': {
                ...hideFocusBorder,
              },

              ':focus': {
                ...hideFocusBorder,
              },

              ':before': {
                content: '""',
                display: 'block',
              },
            },

            '> .DayPicker-NavButton.DayPicker-NavButton--interactionDisabled': {
              cursor: 'default',
              opacity: '0.5',
            },

            '> .DayPicker-NavButton.DayPicker-NavButton--prev': {
              transform: 'rotate(90deg)',
              backgroundPosition: '50% 4px',
            },

            '> .DayPicker-NavButton.DayPicker-NavButton--next': {
              transform: 'rotate(-90deg)',
              backgroundPosition: '50% 10px',
            },
          },

          '> .DayPicker-Months': {
            '> .DayPicker-Month': {
              '> .DayPicker-Caption': {
                textAlign: 'center',
                fontDefaultSemiBold: 18,
                lineHeight: '20px',
                color: colors.space,
              },

              '> .DayPicker-Weekdays': {
                marginTop: '16px',

                '> .DayPicker-WeekdaysRow': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontDefaultSemiBold: 16,
                  lineHeight: '20px',
                  color: colors.indigoCrush,

                  '> .DayPicker-Weekday': {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '28px',
                    cursor: 'default',

                    '> abbr': {
                      textDecoration: 'none',
                      cursor: 'default',
                    },
                  },
                },
              },

              '> .DayPicker-Body': {
                marginTop: '23px',

                '> .DayPicker-Week': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '12px',
                  fontDefaultSemiBold: 16,
                  lineHeight: '20px',
                  color: colors.space,

                  ':last-of-type': {
                    marginBottom: '0',
                  },

                  '> .DayPicker-Day': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '28px',
                    height: '28px',
                    cursor: 'pointer',
                    WebkitFontSmoothing: 'inherit',

                    ':active': {
                      ...hideFocusBorder,
                    },

                    ':focus': {
                      ...hideFocusBorder,
                    },
                  },

                  '> .DayPicker-Day--today': {
                    color: colors.blushTangerine120,
                  },

                  '> .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
                    {
                      color: colors.white,
                      backgroundColor: colors.indigoCrush,
                      borderRadius: '100%',
                    },

                  '> .DayPicker-Day--selected.DayPicker-Day--disabled': {
                    color: colors.space40,
                    backgroundColor: colors.space15,
                    borderRadius: '100%',
                  },

                  '> .DayPicker-Day.DayPicker-Day--disabled': {
                    color: colors.space30,
                    cursor: 'default',
                  },
                },
              },
            },
          },
        },
      },
    },

    offHide: {
      display: shown ? 'block' : 'none',
      position: 'fixed',
      zIndex: 2000,
      width: '100%',
      top: '0',
      left: '0',
      height: '100vh',
      background: 'none',
    },
  };
};

export default styles;
