import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { mapToExactDate } from '@bridebook/toolbox/src';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { IEnquiryFormFields, IWeddingFieldsToSend } from 'lib/enquiry-form/atoms';
import enquirySupplierTrack from 'lib/server-side-track';

const supplierPropertiesGeneric = (supplier: ISupplier) => {
  const { id: supplierId, type, name: supplierName } = supplier;
  const countryCode = supplier?.l10n?.country;
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;

  return {
    supplierId,
    supplierCategory: type?.[0],
    supplierName,
    countryCode,
    countryName,
  };
};

const getAddedEnquiryPropertiesGeneric = (
  weddingFields: IWeddingFieldsToSend,
  supplierWeddingId: string,
  supplier: ISupplier,
  enquiryFormFields: IEnquiryFormFields,
  enquiryId?: string,
) => {
  const { message, weddingDateDatePicker } = enquiryFormFields;
  const market = gazetteer.getMarketByCountry(supplier.l10n?.country, CountryCodes.GB);
  const dateFieldValue = weddingDateDatePicker
    ? mapToExactDate(weddingDateDatePicker, market)
    : undefined;

  return {
    enquiryId,
    enquiryPartnerName1: weddingFields.partners[0],
    enquiryPartnerName2: weddingFields.partners[1] || '',
    enquiryEmail: weddingFields.contacts.email,
    enquiryPhone: weddingFields.contacts.phone || '',
    enquiryGuests: weddingFields.guests?.estimate,
    enquiryWeddingDate: dateFieldValue,
    enquiryMessage: message,
    enquiryStatus: 'unread',
    supplierWeddingId,
  };
};

const trackSentWidgetEnquiry = (
  weddingFields: IWeddingFieldsToSend,
  supplierWeddingId: string,
  supplier: ISupplier,
  enquiryFormFields: IEnquiryFormFields,
  enquiryId: string,
) => {
  enquirySupplierTrack({
    event: 'Sent widget enquiry',
    specificEventProps: {
      ...getAddedEnquiryPropertiesGeneric(
        weddingFields,
        supplierWeddingId,
        supplier,
        enquiryFormFields,
        enquiryId,
      ),
      ...supplierPropertiesGeneric(supplier),
    },
    contactedSupplier: supplier,
    identifyProps: { userEmail: weddingFields.contacts?.email || '' },
  });
};

const trackClickedToSendEnquiry = (
  weddingFields: IWeddingFieldsToSend,
  supplierWeddingId: string,
  supplier: ISupplier,
  enquiryFormFields: IEnquiryFormFields,
) => {
  enquirySupplierTrack({
    event: 'Clicked to send widget enquiry',
    specificEventProps: {
      ...getAddedEnquiryPropertiesGeneric(
        weddingFields,
        supplierWeddingId,
        supplier,
        enquiryFormFields,
      ),
      ...supplierPropertiesGeneric(supplier),
    },
    contactedSupplier: supplier,
    identifyProps: { userEmail: weddingFields.contacts.email },
  });
};

export { trackClickedToSendEnquiry, trackSentWidgetEnquiry };
