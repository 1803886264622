import i18next, { i18n as I18NextClient } from 'i18next';
import * as nextI18next from 'next-i18next';

let cachedInstance: I18NextClient | null = null;

export const getI18n = (): I18NextClient => {
  const properI18nInstance = nextI18next.i18n as I18NextClient | null;

  if (properI18nInstance) {
    return properI18nInstance;
  }

  if (!cachedInstance) {
    cachedInstance = i18next.createInstance();
    cachedInstance.init();
  }

  return cachedInstance;
};
