import fetch from 'isomorphic-fetch';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';

export const fetchSupplierPromise = (supplierId: string): Promise<ISupplier> =>
  fetch(`/api/supplier/${supplierId}`).then((response) => {
    if (response.status === 200) {
      return response.json();
    }

    throw response;
  });
