import React, { FunctionComponent } from 'react';
import Box from '../../../components/fela/Box';
import IconAttentionTriangle from '../../../components/svg/icon-attention-triangle';
import { IStylingProps } from '../../../themes/types';
import componentStyles from './error.style';

export interface SharedTypes {
  errorTextHide?: boolean;
}

interface PropTypes extends SharedTypes, IStylingProps {
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  showIcon?: boolean;
}

const Error: FunctionComponent<PropTypes> = ({
  error,
  errorText,
  disabled,
  errorTextHide,
  showIcon = true,
  ...restProps
}) => {
  const styles = componentStyles({ errorTextHide });

  const content =
    error && errorText && !disabled && !errorTextHide ? (
      <Box style={styles.errorMessageWrap} {...restProps}>
        {showIcon && <IconAttentionTriangle color="blushTangerine120" width={16} />}
        <Box style={styles.errorMessageText}>{errorText}</Box>
      </Box>
    ) : null;

  return content;
};

export default Error;
