import { FelaCSS } from '@bridebook/ui/src/components/fela/flowtypes';
import colors from '@bridebook/ui/src/themes/variables/colors';

interface IStyles {
  formWrapper: FelaCSS;
  formField: FelaCSS;
  heading: FelaCSS;
  footer: FelaCSS;
  terms: FelaCSS;
  poweredBy: FelaCSS;
  poweredByWrap: FelaCSS;
  termsButton: FelaCSS;
  loadingWrap: FelaCSS;
}

const styles = (): IStyles => ({
  formWrapper: {
    padding: '16px',
  },

  formField: {
    marginBottom: '16px',
  },

  heading: {
    fontDefault: 16,
    color: colors.space75,
    textAlign: 'center',
    padding: '16px',
  },

  footer: {
    marginTop: '16px',
    marginBottom: '8px',
  },

  terms: {
    textAlign: 'center',
    display: 'inline',
    fontDefault: 12,
    color: colors.space75,
  },

  termsButton: {
    fontDefault: 12,
    color: colors.space75,
    textDecoration: 'underline',

    ':active': {
      color: colors.space75,
      textDecoration: 'none',
    },

    ':visited': {
      color: colors.space75,
      textDecoration: 'none',
    },

    ':hover': {
      color: colors.space75,
      textDecoration: 'none',
    },
  },

  poweredByWrap: {
    alignItems: 'center',
    marginTop: '16px',
  },

  poweredBy: {
    fontDefault: 8,
    color: colors.space75,
    marginBottom: '4px',
  },

  loadingWrap: {
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
