import React, {
  ChangeEventHandler,
  FocusEventHandler,
  LegacyRef,
  ReactNode,
  RefCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CustomNameValueEvent } from 'src/types';
import { ValidationError } from '@bridebook/toolbox/src';
import { IStylingProps } from '@bridebook/ui/src/themes/types';
import { useInputErrorState, useInputValueListener } from '@bridebook/ui/src/utils/hooks';
import { extractStylingProps } from '@bridebook/ui/src/utils/styling-props';
import Box from '../../../../components/fela/Box';
import Text from '../../../../components/fela/Text';
import Error from '../error';
import Label from '../label';
import componentStyles from './text-field.style';

export interface SharedTypes {
  disabled?: boolean;
  rows?: number;
  isPlaceholder?: boolean;
}

interface IProps extends SharedTypes, IStylingProps {
  name?: string;
  dataTest?: string;
  id?: string;
  label?: string | ReactNode;
  placeholder?: string;
  required?: boolean;
  error?: boolean | ValidationError | null;
  errorText?: string;
  errorTextHide?: boolean;
  value?: string;
  onChange?: (e: CustomNameValueEvent) => void;
  onBlur?: FocusEventHandler<Element | ReactNode>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  maxLength?: number;
  ref?: LegacyRef<ReactNode> | RefCallback<ReactNode>;
  setRef?: LegacyRef<ReactNode>;
  counter?: boolean;
}

const TextField = (props: IProps) => {
  const {
    id,
    label,
    placeholder,
    required,
    rows = 5,
    disabled,
    errorTextHide,
    value,
    name,
    onChange,
    onBlur,
    onFocus,
    error,
    errorText,
    maxLength,
    ref,
    setRef,
    isPlaceholder,
    dataTest,
    counter,
  } = props;

  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState(value || '');
  const { hasError, errorMessage } = useInputErrorState(error, errorText, name);
  useInputValueListener(value, setStateValue);

  const _onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.currentTarget;
    setStateValue(value);
    if (onChange) onChange({ target: { name, value } });
  };

  const styles = componentStyles({
    disabled,
    hasError,
    isPlaceholder,
  });

  const inputLabel = counter ? (
    <Box flexDirection={'row'} justifyContent={'space-between'}>
      <Label error={hasError} id={id} label={label} required={required} disabled={disabled} />
      <Box style={styles.label}>
        {stateValue?.length || 0}/{maxLength}
      </Box>
    </Box>
  ) : (
    <Label error={hasError} id={id} label={label} required={required} disabled={disabled} />
  );

  return (
    <Box {...extractStylingProps(props)}>
      {inputLabel}
      <Text
        id={id}
        disabled={disabled}
        as="textarea"
        placeholder={placeholder || t('ui:textFieldPlaceholder')}
        value={stateValue}
        style={styles.textarea}
        rows={rows}
        onChange={_onChange}
        onBlur={onBlur}
        name={name}
        dataTest={dataTest}
        onFocus={onFocus}
        maxLength={maxLength}
        ref={ref}
        setRef={setRef as ReturnType<typeof useRef>}
      />
      <Error
        error={hasError}
        errorText={errorMessage}
        disabled={disabled}
        errorTextHide={errorTextHide}
      />
    </Box>
  );
};

export default TextField;
