import React from 'react';
import { IIconProps } from '../../types';
import SimpleIconWrap from './simple-icon-wrap';

const IconCalendar = ({ id, color, width = 51, style, onClick }: IIconProps) => {
  const viewBoxWidth = 42;
  const viewBoxHeight = 49;

  return (
    <SimpleIconWrap
      onClick={onClick}
      id={id}
      viewBox={[0, 0, viewBoxWidth, viewBoxHeight]}
      width={width}
      style={style}
      color={color}
      path="M36.748 5.396c2.896 0 5.252 2.319 5.252 5.168v33.268C42 46.682 39.644 49 36.748 49H5.252C2.356 49 0 46.681 0 43.832V10.564c0-2.85 2.356-5.168 5.252-5.168h4.956V1.86c0-1.027.847-1.86 1.89-1.86 1.045 0 1.891.833 1.891 1.86v3.536h5.12V1.86c0-1.027.847-1.86 1.89-1.86 1.045 0 1.891.833 1.891 1.86v3.536h5.12V1.86C28.01.833 28.856 0 29.9 0c1.045 0 1.891.833 1.891 1.86v3.536h4.957zm1.47 38.436V10.564a1.46 1.46 0 0 0-1.47-1.447H31.79v2.593c0 1.028-.846 1.86-1.89 1.86-1.045 0-1.891-.832-1.891-1.86V9.117h-5.12v2.593c0 1.028-.846 1.86-1.89 1.86s-1.891-.832-1.891-1.86V9.117h-5.12v2.593c0 1.028-.846 1.86-1.89 1.86s-1.891-.832-1.891-1.86V9.117H5.252c-.81 0-1.47.65-1.47 1.447v4.914h29.09c1.045 0 1.892.833 1.892 1.86 0 1.029-.847 1.861-1.891 1.861H3.782v24.633c0 .798.66 1.447 1.47 1.447h31.496c.81 0 1.47-.65 1.47-1.447zm-19.477-21.01c.574 0 1.04.458 1.04 1.022v5.511c0 .565-.466 1.023-1.04 1.023h-5.6c-.574 0-1.04-.458-1.04-1.023v-5.51c0-.565.466-1.023 1.04-1.023h5.6zm0 9.922c.574 0 1.04.458 1.04 1.023v5.51c0 .565-.466 1.024-1.04 1.024h-5.6c-.574 0-1.04-.459-1.04-1.023v-5.511c0-.565.466-1.023 1.04-1.023h5.6zm10.084-9.922c.574 0 1.04.458 1.04 1.022v5.511c0 .565-.466 1.023-1.04 1.023h-5.6c-.574 0-1.04-.458-1.04-1.023v-5.51c0-.565.466-1.023 1.04-1.023h5.6zm0 9.922c.574 0 1.04.458 1.04 1.023v5.51c0 .565-.466 1.024-1.04 1.024h-5.6c-.574 0-1.04-.459-1.04-1.023v-5.511c0-.565.466-1.023 1.04-1.023h5.6zm-14.459-4.716h3.026V25.05h-3.026v2.977zm0 9.922h3.026v-2.977h-3.026v2.977zm10.085-9.922h3.025V25.05H24.45v2.977zm0 9.922h3.025v-2.977H24.45v2.977z"
    />
  );
};

export default IconCalendar;
