import isEmail from 'is-email';
import {
  any,
  assert,
  length,
  number,
  object,
  optional,
  pattern,
  string,
  struct,
  union,
} from 'superstruct';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ValidationError } from '@bridebook/toolbox/src';
import { env } from '@bridebook/toolbox/src/env';
import { ISnackbarState } from 'components/iframe-page';
import { trackSentWidgetEnquiry } from 'lib/enquiry-form/analytics';
import { IEnquiryFormFields, IWeddingFieldsToSend } from 'lib/enquiry-form/atoms';
import { getI18n } from 'lib/i18n/get-i18n';

const getErrorMessages = () => {
  const i18n = getI18n();
  return {
    email: {
      required: i18n.t('enterYourEmail'),
      invalid: i18n.t('emailNotInCorrectFormat'),
    },
    partners: {
      invalid: i18n.t('enterValidPhoneNumber'),
      required: i18n.t('enterValidPhoneNumber'),
    },
  };
};

const Email = struct<string>('Email', (value) => isEmail(value as string));
const WeddingDate = object({
  d: union([string(), number()]),
  m: union([string(), number()]),
  y: union([string(), number()]),
});

const EnquiryFormStruct = object({
  email: Email,
  partnerName1: length(pattern(string(), /^[a-zA-Z\s]*$/), 1, Infinity),
  partnerName2: optional(pattern(string(), /^[a-zA-Z\s]*$/)),
  phone: optional(pattern(string(), /^$|[\s()+-]*([0-9][\s()+-]*){6,20}(?:[.\- ])?/)),
  guestsInitialTarget: optional(number()),
  message: optional(string()),
  weddingDate: optional(WeddingDate),
  weddingDateDatePicker: any(),
});

export const validate = (enquiryFormFields: IEnquiryFormFields) => {
  try {
    assert(enquiryFormFields, EnquiryFormStruct);
    return true;
  } catch (e) {
    const { path, value } = e;
    const prop = path[0] === 'partners' ? 'partnerName1' : path[0];

    if (value === undefined || value === '') {
      const message = getErrorMessages()?.[prop]?.required || getI18n().t('fieldRequired');
      throw new ValidationError(message, prop);
    }

    const message = getErrorMessages()?.[prop]?.invalid || getI18n().t('enterValidValue');
    throw new ValidationError(message, prop);
  }
};

const submitEnquiry = (
  supplier: ISupplier | null,
  weddingFields: IWeddingFieldsToSend,
  disableForm: (disable: boolean) => any,
  toggleSnackBar: (newState: ISnackbarState) => void,
  enquiryFormFields: IEnquiryFormFields,
) => {
  const i18n = getI18n();

  if (supplier?.id) {
    const { message } = enquiryFormFields;
    fetch(`${env.COUPLESIDE_URL}/api/enquiries/send-anonymous-enquiry`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        supplierId: supplier.id,
        message,
        weddingFields,
        source: 'widget',
      }),
    }).then(async (res) => {
      if (!res.ok) {
        throw new Error('createEnquiry: API error');
      }

      const response = await res.json();
      const supplierWeddingId = response?.id || '';
      const enquiryId = response?.enquiryId || '';
      await trackSentWidgetEnquiry(
        weddingFields,
        supplierWeddingId,
        supplier,
        enquiryFormFields,
        enquiryId,
      );
      /*
       * Analytics will go here soon
       */
      disableForm(false);
      const defaultSupplierName = i18n.t('defaultSupplierName');
      toggleSnackBar({
        show: true,
        mode: 'success',
        text: i18n.t('enquirySentTo', {
          supplier: supplier?.name || defaultSupplierName,
        }),
      });
    });
  }
};

export default submitEnquiry;
