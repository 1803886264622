import React from 'react';
import { useRecoilState } from 'recoil';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import {
  ValidationError,
  mapDateToUI,
  mapToDatepickerObject,
  mapToExactDate,
} from '@bridebook/toolbox/src';
import Datepicker from '@bridebook/ui/src/components/bbcommon/datepicker/datepicker';
import Box from '@bridebook/ui/src/components/fela/Box';
import { enquiryFormFieldsState } from 'lib/enquiry-form/atoms';
import { getOptionalFields } from 'lib/enquiry-form/enquiry-form';
import { getLocalisedMonths, getLocalisedWeekdays } from 'lib/i18n/get-date-translations';
import componentStyles from './enquiry-form.style';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

interface IProps {
  supplier: ISupplier;
  setError: (error: null | ValidationError) => void;
  error: null | ValidationError;
}

const WeddingDateField = ({ supplier, setError, error }: IProps) => {
  const [enquiryFormFields, setEnquiryFormFields] = useRecoilState(enquiryFormFieldsState);
  const styles = componentStyles();

  const weddingDateChange = (date: string) => {
    setError(null);
    const weddingDate = mapToDatepickerObject(date);
    const weddingDateDatePicker = mapDateToUI(weddingDate);
    setEnquiryFormFields({
      ...enquiryFormFields,
      weddingDate,
      weddingDateDatePicker,
    });
  };

  const dateFieldValue = mapToExactDate(
    enquiryFormFields.weddingDateDatePicker,
    supplier?.l10n?.country === CountryCodes.GB,
  );

  return (
    <Box style={styles.formField}>
      <Datepicker
        name={getOptionalFields().weddingDate.name}
        value={dateFieldValue}
        onChangeFormatted={weddingDateChange}
        disableBeforeToday
        returnFormat="yyyy-MM-dd"
        placeholder={getOptionalFields().weddingDate.placeholder}
        error={Boolean(error && error.prop === getOptionalFields().weddingDate.name)}
        errorText={error?.message || ''}
        months={getLocalisedMonths()}
        weekdaysShort={getLocalisedWeekdays('short', 0)}
      />
    </Box>
  );
};

export default WeddingDateField;
