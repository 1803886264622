import { Locales, RoutingLocales, defaultLocale } from './constants';

const localesMap: Record<Locales, RoutingLocales> = {
  [Locales.GB]: RoutingLocales.UK,
  [Locales.DE]: RoutingLocales.DE,
  [Locales.FR]: RoutingLocales.FR,
  [Locales.IE]: RoutingLocales.IE,
};

const getCouplesideLocale = (locale: Locales): string =>
  localesMap[locale] || localesMap[defaultLocale as Locales];

export default getCouplesideLocale;
