import { FelaCSS } from '../../../components/fela/flowtypes';
import { alpha } from '../../../fela-utils/color-mixer';
import colorParse from '../../../fela-utils/color-parse';
import { colors } from '../../../themes/variables';
import { SharedTypes as PropTypes } from './label';

interface StyleTypes {
  labelWrapper: FelaCSS;
  labelRequired: FelaCSS;
}

const styles = ({ disabled, error, color }: PropTypes): StyleTypes => {
  const space50 = alpha(colors.space, 50);
  const fontColor = color ? colorParse(color) : colors.space;

  return {
    labelWrapper: {
      flexShrink: 0,
      flexDirection: 'row',
      fontDefaultSemiBold: 14,
      lineHeight: '20px',
      color: disabled ? space50 : fontColor,
    },

    labelRequired: {
      marginLeft: '2px',
      color: disabled ? space50 : error ? colors.blushTangerine120 : fontColor,
    },
  };
};

export default styles;
