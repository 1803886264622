import {
  Locale as DateFnsLocale,
  addDays,
  addMonths,
  format as dateFnsFormat,
  startOfWeek,
} from 'date-fns';
import { de, enGB, enIE, fr } from 'date-fns/locale';
import { getI18n } from 'lib/i18n/get-i18n';
import { Locales } from './constants';

const localeToDateFnsLocale: Record<Locales, DateFnsLocale | null> = {
  [Locales.DE]: de,
  [Locales.FR]: fr,
  [Locales.GB]: enGB,
  [Locales.IE]: enIE,
};

/**
 * Returns date-fns language files based on the current next-i18next locale
 */
const getDateFnsLocale = () => {
  const language = getI18n().language as Locales;

  return localeToDateFnsLocale[language] || enGB;
};

/**
 * Wrapper over date-fns format, which has localisation options passed
 */
export const format: typeof dateFnsFormat = (date, format, options) =>
  dateFnsFormat(date, format, {
    locale: getDateFnsLocale(),
    ...options,
  });

const monthFormat = {
  full: 'MMMM', // January, February, ..., December
  medium: 'MMM', // Jan, Feb, ..., Dec
};

const weekdayFormat = {
  full: 'EEEE', // Monday, Tuesday, ..., Sunday
  medium: 'EEE', // Mon, Tue, Wed, ..., Sun
  short: 'EEEEEE', // Mo, Tu, We, Th, Fr, Su, Sa
};

/**
 * Generates an array of names for all days of week
 */
export const getLocalisedWeekdays = (
  length: keyof typeof weekdayFormat = 'full',
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 1,
) => {
  const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn });

  return Array.from(Array(7)).map((_, i) =>
    format(addDays(firstDayOfWeek, i), weekdayFormat[length]),
  );
};

/**
 * Generates an array of names for all months
 */
export const getLocalisedMonths = (length: keyof typeof monthFormat = 'full') =>
  Array.from(Array(12)).map((_, i) => format(addMonths(0, i), monthFormat[length]));
