import { mergeDeepRight } from 'ramda';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  FormEvent,
  KeyboardEventHandler,
  ReactNode,
  useState,
} from 'react';
import { FelaCSS } from 'src/components/fela/flowtypes';
import { useInputErrorState, useInputValueListener } from '@bridebook/ui/src/utils/hooks';
import { extractStylingProps, stripStylingProps } from '@bridebook/ui/src/utils/styling-props';
import Box from '../../../../components/fela/Box';
import TextInput, { IProps as ITextInputProps } from '../../../../components/fela/TextInput';
import IconCrossBold from '../../../../components/svg/icon-cross-bold';
import { colors } from '../../../../themes/variables';
import { IColorTypes, TSetRef } from '../../../../types';
import Button from '../../buttons/button';
import Error from '../error';
import Label from '../label';
import componentStyles from './input.style';

export interface SharedTypes {
  disabled?: boolean;
  backIcon?: Function;
  readOnly?: boolean;
  readOnlyActive?: boolean;
  iconWidth?: number;
}

export interface IProps<E>
  extends SharedTypes,
    Omit<ITextInputProps, 'onChange' | 'onBlur' | 'onKeyDown' | 'onReset' | 'label' | 'ref'> {
  backIcon?: Function;
  backIconColor?: string;
  backIconWidth?: number;
  error?: E | boolean | null;
  errorText?: string;
  errorTextHide?: boolean;
  icon?: Function;
  iconColor?: keyof IColorTypes;
  iconNode?: ReactNode;
  id?: string;
  label?: string | ReactNode;
  maxLength?: number;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onReset?: Function;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  reset?: boolean;
  setRef?: TSetRef;
  showErrorIcon?: boolean;
  type?: string;
  value?: string | number;
  inputStyle?: FelaCSS;
  iconStyle?: FelaCSS;
  endIcon?: ReactNode;
}

const Input = <E extends Error>({
  backIcon,
  backIconColor,
  backIconWidth,
  disabled,
  error,
  errorText,
  errorTextHide,
  icon = undefined,
  iconColor,
  iconNode,
  iconWidth = 10,
  id,
  label,
  maxLength,
  name,
  onChange,
  onReset,
  pattern,
  readOnly,
  readOnlyActive,
  required = false,
  reset,
  setRef,
  showErrorIcon,
  type,
  value,
  inputStyle,
  iconStyle = {},
  style = {},
  endIcon,
  ...restProps
}: IProps<E>) => {
  const [stateValue, setStateValue] = useState<string | number>(value || '');
  const { hasError, errorMessage, resetError } = useInputErrorState(error, errorText, name);
  useInputValueListener(value, setStateValue);

  const _onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    setStateValue(value);
    if (onChange) onChange(e);
  };

  const _onInput = (e: FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    if (type === 'number' && maxLength && value.length > Number(maxLength)) {
      e.currentTarget.value = value.slice(0, Number(maxLength));
    }
  };

  const _onReset = () => {
    setStateValue('');
    resetError();
    if (reset && onChange) onChange({ target: { name, value: stateValue } });
    if (onReset) onReset({ target: { name, value: stateValue } });
  };

  const showReset = reset && (typeof stateValue === 'string' ? stateValue.length > 0 : false);
  const iconColorSet = disabled
    ? colors.space30
    : hasError
    ? 'blushTangerine120'
    : iconColor || 'space';
  const BackIcon = backIcon || null;
  const backIconColorSet = backIconColor || 'white';

  const setPattern = pattern ? pattern : type === 'number' ? '\\d*' : undefined;

  const Icon = icon;

  const styles = componentStyles({
    disabled,
    icon: Boolean(icon || iconNode),
    backIcon,
    readOnly,
    readOnlyActive,
    iconWidth,
    showReset,
    hasError,
  });

  return (
    <Box style={mergeDeepRight(styles.wrapper, style)} {...extractStylingProps(restProps)}>
      <Label error={hasError} id={id} label={label} required={required} disabled={disabled} />
      <Box style={styles.inputWrapper}>
        {iconNode && <Box style={styles.iconWrap}>{iconNode}</Box>}
        {Icon && (
          <Box style={mergeDeepRight(styles.iconWrap, iconStyle)}>
            <Icon color={iconColorSet} width={iconWidth} />
          </Box>
        )}
        <TextInput
          {...stripStylingProps(restProps)}
          id={id}
          name={name}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          style={inputStyle ? { ...styles.input, ...inputStyle } : styles.input}
          onChange={_onChange}
          onInput={_onInput}
          pattern={setPattern}
          value={stateValue}
          setRef={setRef}
          maxLength={maxLength}
        />
        {showReset && (
          <Box style={styles.resetWrap}>
            <Button
              theme="ghost"
              icon={<IconCrossBold width={9} color="space40" />}
              color="custom"
              onClick={_onReset}
              customWidth={44}
              customHeight={44}
            />
          </Box>
        )}
        {BackIcon && (
          <Box style={styles.backIconWrap}>
            <BackIcon color={backIconColorSet} width={backIconWidth} />
          </Box>
        )}
        {!!endIcon && <Box style={styles.endIconWrap}>{endIcon}</Box>}
      </Box>

      <Error
        error={hasError}
        errorText={errorMessage}
        disabled={disabled}
        errorTextHide={errorTextHide}
        showIcon={showErrorIcon}
      />
    </Box>
  );
};

export default Input;
